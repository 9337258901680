<template>
  <div class="actions">
    <router-link
      :to="{name: 'analyse_detail', params: {uid: object.uid}}"
      class="btn btn-secondary btn-sm"
    >
      <i class="icofont icofont-eye" />
    </router-link>
    <router-link 
      :to="{ name: 'analyse_update', params: { uid: object.uid } }"
      class="btn btn-primary btn-sm"
      v-if="canUpdate"
    >
      <i class="icofont icofont-pencil" />
    </router-link>
    <a
      class="btn btn-third btn-sm"
      @click.prevent="deleting"
      v-if="canDelete"
    ><i class="icofont icofont-close" /></a>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { DELETE_ANALYSE } from '../../../graphql/sanitaire';
export default {
    props : {
        object: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    methods: {
        deleting(){
            this.$apollo.mutate({
                mutation: DELETE_ANALYSE,
                variables: {
                    "uid": this.object.uid
                },
                update: (data) => {
                    if(data) console.log(`Analyse ${this.object.uid} deleted successfully`)
                    else console.log(`Error in deletting analyse ${this.object.uid}`)
                }
              })
        }
    },
    computed: {
        ...mapGetters({
            is_super_admin: 'auth/is_super_admin',
            is_admin: 'auth/is_admin',
            is_veterinaire: 'auth/is_veterinaire',
            is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
            is_assistant_veto_major: 'auth/is_assistant_veto_major',
            is_labo_user: 'auth/is_labo_user',
            is_technicien_imagerie: 'auth/is_technicien_imagerie'
        }),
        canUpdate(){
          return this.is_super_admin || this.is_veterinaire
        },
        canDelete(){
          return this.is_admin || this.is_veterinaire
        }
    }

}
</script>

<style>

</style>